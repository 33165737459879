import {createApp} from 'vue'
import App from './App.vue'
import router from '@/configure/router'
import store from '@/configure/store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/style/baseSystemStyle.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn';

const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus, {
    locale: zhCn,
});
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 加的网页title
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  })
app.mount('#app')
