import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    userData: {
      lesseeManageToken: '',
      lesseeAdminToken: '',
      lesseeManageData: {},
      lesseeAdminData: {},
      authorityData: [],
      adminType: '', //1：主管理员 2：子管理员
      CodeImgData:{} // 二维码信息
    },
    systemMetaBaseData: {
      // 系统名称
      systemName: '',
      // 系统简化名称
      systemMiniName: '',
      // 系统简介
      systemDescribe: '',
      // 系统logo
      systemLogo: null,
      // 后台登录背景图文件
      systemLoginBackgroundFile: null,
      // 后台子系统主页背景图文件
      systemMainBackgroundFile: null
    },
    systemMetaCopyrightData: {
      // 版权名称
      systemCopyrightName: '',
      // 版权网站
      systemCopyrightWebsite: '',
      // 版权时间
      systemCopyrightDay: '',
      // 版权ICP备案
      systemCopyrightICP: '',
      // 版权公安备案
      systemCopyrightSecurity: '',
      // 版权状态 0：不显示； 1：显示
      systemCopyrightState: 0
    }
  },
  mutations: {

    // 保存用户信息
    saveUserData: function (state, data) {
      state.userData.lesseeManageToken = data.lesseeManageToken
      state.userData.lesseeManageData = data.lesseeManageData
      // state.userData.authorityData = data.authorityData
    },
    // 保存权限信息
    saveUserAuthorityData: function (state, data) {
      state.userData.authorityData = data.authorityData.authorityData
      state.userData.adminType = data.authorityData.adminType

    },
    // 删除用户信息
    deleteUserData: function (state) {
      state.userData.lesseeManageToken = ''
      state.userData.lesseeAdminToken = ''
      state.userData.lesseeManageData = {}
      state.userData.lesseeAdminData = {}
      state.userData.authorityData = []
    },
    // 保存用户信息
    saveLesseeAdminToken: function (state, data) {
      state.userData.lesseeAdminToken = data.lesseeAdminToken
      state.userData.lesseeAdminData = data.lesseeAdminData
    },
    // 保存系统基础信息
    saveSystemMetaBaseData: function (state, data) {
      state.systemMetaBaseData.systemName = data.systemName
      state.systemMetaBaseData.systemMiniName = data.systemMiniName
      state.systemMetaBaseData.systemLogo = data.systemLogo
      // state.systemMetaBaseData.systemDescribe = data.systemDescribe
      state.systemMetaBaseData.systemLoginBackgroundFile = data.systemLoginBackgroundFile
      state.systemMetaBaseData.systemMainBackgroundFile = data.systemMainBackgroundFile
    },
    // saveSystemLogo: function(state,data){
    //   state.systemMetaBaseData.systemLogo = data.systemLogo
    // },
    // 删除系统基础信息
    deleteSystemMetaBaseData: function (state) {
      state.systemMetaBaseData.systemName = ''
      state.systemMetaBaseData.systemMiniName = ''
      state.systemMetaBaseData.systemLogo = ''
      state.systemMetaBaseData.systemDescribe = ''
      state.systemMetaBaseData.systemLoginBackgroundFile = ''
      state.systemMetaBaseData.systemMainBackgroundFile = ''
    },
    // 保存系统版权信息
    saveSystemMetaCopyrightData: function (state, data) {
      state.systemMetaCopyrightData.systemCopyrightName = data.systemCopyrightName
      state.systemMetaCopyrightData.systemCopyrightWebsite = data.systemCopyrightWebsite
      state.systemMetaCopyrightData.systemCopyrightDay = data.systemCopyrightDay
      state.systemMetaCopyrightData.systemCopyrightICP = data.systemCopyrightICP
      state.systemMetaCopyrightData.systemCopyrightSecurity = data.systemCopyrightSecurity
      state.systemMetaCopyrightData.systemCopyrightState = data.systemCopyrightState
    },
    // 删除系统版权信息
    deleteSystemMetaCopyrightData: function (state) {
      state.systemMetaCopyrightData.systemCopyrightName = ''
      state.systemMetaCopyrightData.systemCopyrightWebsite = ''
      state.systemMetaCopyrightData.systemCopyrightDay = ''
      state.systemMetaCopyrightData.systemCopyrightICP = ''
      state.systemMetaCopyrightData.systemCopyrightSecurity = ''
      state.systemMetaCopyrightData.systemCopyrightState = ''
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
