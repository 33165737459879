import { createRouter, createWebHistory } from 'vue-router'



const routes = [
  {
    // 官网
    path: "/",
    name: "Home",
    meta: {
      title: '千信云协同办公'
    },
    component: () => import(/* webpackChunkName: "Home" */ "../../views/homeSystem/officialHome.vue")
  },
  {
    // 官网
    path: "/privacyClause",
    name: "privacyClause",
    meta: {
      title: '千信云协同办公'
    },
    component: () => import(/* webpackChunkName: "Home" */ "../../views/homeSystem/privacyClause.vue")
  },
  {
    // 官网
    path: "/privacyClause2",
    name: "privacyClause2",
    meta: {
      title: '千信云协同办公'
    },
    component: () => import(/* webpackChunkName: "Home" */ "../../views/homeSystem/privacyClause2.vue")
  },
  {
    // OA手机端 注册页
    path:'/appZc',
    name: 'appZc',
    meta: {
      title: "注册页"
    },
    component: () => import(/* webpackChunkName: "appZc" */ "../../views/homeSystem/appZc.vue")
  },
  {
    // OA手机端 注册页
    path:'/helpCenter',
    name: 'helpCenter',
    meta: {
      title: "帮助中心"
    },
    component: () => import(/* webpackChunkName: "appZc" */ "../../views/helpCenter/index.vue")
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
